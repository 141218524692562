import React from 'react'
import styled from 'styled-components';
import HeroBgAnimation from '../HeroBgAnimation'
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {
    HeroBg,
    HeroContainer,
    HeroInnerContainer,
    HeroLeftContainer,
    HeroRightContainer,
    Img,
    OrderButton,
    Span,
    SubTitle,
    TextLoop,
    Title
} from './HeroStyle'
import Typewriter from 'typewriter-effect';


const ContainerContact = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;

  @media (max-width: 960px) {
    margin-left: 0;
  }
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({theme}) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({theme}) => theme.primary};
  }
`;

const HeroSection = ({Bio}) => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroBgAnimation/>
                </HeroBg>
                <HeroInnerContainer>
                    <HeroLeftContainer id="Left">
                        <Title>Привет, я <br/> {Bio.name}</Title>
                        <TextLoop>
                            Я
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <ContainerContact>
                          <OrderButton href={Bio.resume} target='display'>Посмотреть резюме</OrderButton>
                          <SocialMediaIcons>
                              <SocialMediaIcon href={Bio.kwork} target="display"><BusinessCenterIcon/></SocialMediaIcon>
                              <SocialMediaIcon href={Bio.github} target="display"><GitHubIcon/></SocialMediaIcon>
                              <SocialMediaIcon href={Bio.telegram} target="display"><TelegramIcon/></SocialMediaIcon>
                          </SocialMediaIcons>
                        </ContainerContact>
                    </HeroLeftContainer>

                    <HeroRightContainer id="Right">
                        <Img src={Bio.image} alt="hero-image"/>
                    </HeroRightContainer>
                </HeroInnerContainer>

            </HeroContainer>
        </div>
    )
}

export default HeroSection
